<template>
	<!-- Contenedor principal con disposición en columna -->
	<div class="flex flex-col pt-4">
		<!-- Título de la sección -->
		<div class="text-base font-semibold text-gray-800">Hora finalización asamblea</div>

		<!-- Selector de fecha para la hora de finalización de la asamblea -->
		<DatePicker
			:disabled="!canEditDate"
			v-model="assemblyServiceDateFinished"
			id="assembly-service-date-finished"
			@hide="onUpdateServiceDateFinished"
			dateFormat="DD, d 'de' M 'del' yy"
			showIcon
			showTime
			hourFormat="12"
			fluid
			class="p-0 shadow-none" />

		<!-- Sección con información adicional sobre la asamblea -->
		<div class="grid grid-cols-2 pt-4 px-4">
			<div>
				<!-- Duración de la asamblea (calculado automáticamente) -->
				<div class="text-base font-semibold text-gray-800">Duración asamblea</div>
				<InputText :disabled="true" v-model="serviceExtraHours" id="service-extra-hours" />
			</div>
			<div>
				<!-- Cantidad de operarios involucrados -->
				<div class="text-base font-semibold text-gray-800">Cantidad operarios</div>
				<div v-for="(manager, index) in assemblyData.managersQnty" :key="index">{{ manager.type }} : {{ manager.quantity }}</div>
			</div>
		</div>
	</div>

	<!-- Sección de operarios agendados -->
	<div class="flex gap-2 w-full items-center justify-between pt-4">
		<div class="text-xl text-gray-600 font-semibold">Operarios agendados</div>
		<Button @click="openManagerAdmnNewTab" :label="getLabelManagerAdm()" />
	</div>

	<!-- Tabla con los operarios agendados -->
	<ManagerTable :managers="managersBooked" :isReadOnly="isReadOnly" />
</template>

<script setup>
import { ref, onMounted } from "vue";
import { DatePicker } from "primevue";
import ManagerTable from "../manager/managerSection/managerTable.vue";
import { useDayjs } from "../../composables/useDayjs";
import { updateAssemblyService } from "../../services/assembly";
import { getManagersBookedByAssemblyNumber } from "../../services/manager";
import { craftManagers } from "../../workshop/manager";
import { useItemDialog } from "../../composables/useItemDialog";

// Definición de props del componente
const props = defineProps({
	assemblyData: { type: Object, required: true },
	isReadOnly: { type: Boolean, default: false }, // Control de edición
	canEditDate: { type: Boolean, default: false }, // Permiso para editar fecha
});

const emit = defineEmits(["loadSection"]);
const { updateAssemblyDataDialog } = useItemDialog();

// Variables reactivas
const assemblyServiceDateFinished = ref(null);
const serviceExtraHours = ref("");
const managersBooked = ref([]);

const dayjs = useDayjs();

// Abre una nueva pestaña para administrar/agendar operarios
const openManagerAdmnNewTab = () => {
	const url =
		import.meta.env.VITE_JOGET_BASE_URL +
		(managersBooked.value.length > 0 ? import.meta.env.VITE_LIST_ADM_OPERARIOS : import.meta.env.VITE_LIST_AGREGAR_OPERARIOS) +
		props.assemblyData?.assemblyCotNumber;
	window.open(url, "_blank");
};

// Retorna la etiqueta dinámica del botón de administración de operarios
const getLabelManagerAdm = () => (managersBooked.value.length > 0 ? "Administrar agenda operarios" : "Agendar operarios");

// Calcula la duración de la asamblea en horas y minutos
const mapHorasExtras = () => {
	const dayjsDateFinishedObject = dayjs(assemblyServiceDateFinished.value);

	if (!dayjsDateFinishedObject.isValid()) return "No hay fecha";

	let totalMinutes = dayjsDateFinishedObject.diff(dayjs(props.assemblyData?.assemblyServiceFirstAnnoucementDate), "minutes");

	if (isNaN(totalMinutes)) return "No hay fecha";

	let hours = Math.floor(totalMinutes / 60);
	let minutes = totalMinutes % 60;
	return `${hours} horas y ${minutes} minutos`;
};

// Maneja la actualización de la fecha de finalización de la asamblea
const onUpdateServiceDateFinished = async () => {
	const updatedDateFinished = dayjs(assemblyServiceDateFinished.value);

	if (!updatedDateFinished.isValid()) return;

	serviceExtraHours.value = mapHorasExtras();

	const requestData = {
		id: props.assemblyData?.assemblyCotNumber,
		hora_finalizacion_asamblea: updatedDateFinished.format("DD-MM-YYYY hh:mm a"),
	};

	try {
		emit("loadSection", true);

		const request = await updateAssemblyService(requestData);

		updateAssemblyDataDialog(props.assemblyData?.assemblyId, {
			assemblyServiceDateFinished: dayjs(assemblyServiceDateFinished.value),
			serviceExtraHours: serviceExtraHours.value,
		});
	} catch (error) {
		console.error(error);
	} finally {
		emit("loadSection", false);
	}
};

// Inicializa los valores al montar el componente
onMounted(async () => {
	assemblyServiceDateFinished.value = dayjs(props.assemblyData?.assemblyServiceDateFinished).isValid() ? props.assemblyData?.assemblyServiceDateFinished.toDate() : null;

	serviceExtraHours.value = mapHorasExtras();

	try {
		emit("loadSection", true);

		const managersBookedData = await getManagersBookedByAssemblyNumber({
			pageSize: 50,
			cot: props.assemblyData?.assemblyCotNumber,
		});

		// Simulación de transformación de datos debido a limitaciones en el backend
		const craftedManagersBooked = craftManagers(managersBookedData.data);

		managersBooked.value = craftedManagersBooked;
	} catch (error) {
		console.error(error);
	} finally {
		emit("loadSection", false);
	}
});
</script>
