<template>
	<DataTable :rowClass="rowClass" :value="managers" :loading="isLoading">
		<template #loading> Actualizando... </template>

		<Column field="managerName" header="Nombre"></Column>
		<Column field="managerConfirmed" header="Confirma"></Column>
		<Column field="managerFunction" header="Función"></Column>
		<Column>
			<template #body="{ data }">
				<Button class="text-white" icon="pi pi-palette" rounded severity="help" @click="updateRowColor(data)" :disabled="isReadOnly" :class="{ 'opacity-50 cursor-not-allowed': isReadOnly }" />
			</template>
		</Column>
	</DataTable>
</template>

<script setup>
import { ref } from "vue";
import { updateManagerAvailability } from "../../../services/manager";

const props = defineProps({
	managers: {
		type: Object,
		required: true,
		default: [],
	},
	isReadOnly: {
		type: Boolean,
		default: false,
	},
});

const isLoading = ref(false);

const colors = [
	{ color: "bg-green-200", index: 0 },
	{ color: "bg-blue-200", index: 1 },
	{ color: "bg-yellow-200", index: 2 },
	{ color: "bg-red-200", index: 3 },
	{ color: "bg-white", index: 4 },
];

const getNextColor = (index) => {
	if (index === 4) return 0;
	else return index + 1;
};

const updateRowColor = async (item) => {
	const index = colors.findIndex((c) => c.color === item.managerColorStatus);
	const nextColor = colors[getNextColor(index)];

	const requestData = {
		id: item.managerId,
		bgcolor: nextColor.color,
	};

	try {
		isLoading.value = true;

		const request = await updateManagerAvailability(requestData);

		console.log(request);
	} finally {
		item.managerColorStatus = nextColor.color;
		isLoading.value = false;
	}
};

const rowClass = (data) => {
	if (data.managerColorStatus === "bg-blue-200" || data.managerColorStatus === "bg-green-200" || data.managerColorStatus === "bg-red-200") {
		return "text-white " + data.managerColorStatus;
	} else return data.managerColorStatus;
};
</script>
