<template>
  <Section title="Informes" :loading="isLoading" v-if="inputs.length > 0">
    <template #content>
      <FormHorizontalLayout>
        <template v-for="(input, index) in inputs">
          <FormInput
            :id="index.toString()"
            :inputType="input.inputType"
            :label="input.label"
            v-model="formData[input.value]"
            :dropdownOptions="signaturesStore.signatures"
            dropdownOptionLabel="sig_name"
            @onChange="showUpdateFormButton = true"
            :disabled="input.readonly"
          ></FormInput>
        </template>
      </FormHorizontalLayout>

      <Button
        label="Guardar cambios"
        icon="pi pi-check"
        :class="buttonSaveClass"
        @click="saveReportsData"
      />
    </template>
  </Section>
</template>
<script setup>
const props = defineProps({
  assemblyData: Object,
  roleUser: String,
});

import Section from "./Section.vue";

import { useModalSection } from "../../composables/useModalSection";
import FormHorizontalLayout from "../aligment/FormHorizontalLayout.vue";
import { computed, onMounted, ref } from "vue";
import _ from "lodash";
import FormInput from "./reportsSection/FormInput.vue";
import modalTypes from "../../enums/modalTypes";

import { useSignaturesStore } from "../../stores/signatures";
import { getSignatures } from "../../services/signatures";

import { useDayjs } from "../../composables/useDayjs";
import { updateReport } from "../../services/reports";
import { useItemDialog } from "../../composables/useItemDialog";

const { updateAssemblyDataDialog } = useItemDialog();

const signaturesStore = useSignaturesStore();

const dayjs = useDayjs();

const { getReportsSectionData } = useModalSection();

const { inputs } = getReportsSectionData(props.roleUser);

const saveReportsData = async () => {
  const requestData = {
    id: props.assemblyData.assemblyCotNumber,
    fecha_envio: dayjs(formData.value.reportsDateSend).isValid()
      ? dayjs(formData.value.reportsDateSend).format("DD-MM-YYYY")
      : null,
    correo_envia_informes: formData.value.assemblyServiceReportsMail,
    enviado_por: formData.value.reportsSentBy?.id ?? null,
    revisado_por: formData.value.reportsReviewedBy?.id ?? null,
    status_to_upload: formData.value.reportsRemarks,
  };

  try {
    isLoading.value = true;
    const response = await updateReport(requestData);

    updateAssemblyDataDialog(props.assemblyData.assemblyId, {
      reportsDateSend: formData.value.reportsDateSend ?? null,
      assemblyServiceReportsMail: formData.value.assemblyServiceReportsMail,
      reportsSentBy: formData.value.reportsSentBy,
      reportsReviewedBy: formData.value.reportsReviewedBy,
      reportsRemarks: formData.value.reportsRemarks,
    });

    console.log(response);
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

const elements = inputs.reduce((acc, curr) => {
  if (!curr["value"]) return acc;

  let prop = props.assemblyData[curr["value"]];

  if (curr["inputType"] === modalTypes.DATE) {
    prop = dayjs(props.assemblyData[curr["value"]]).isValid()
      ? dayjs(props.assemblyData[curr["value"]]).toDate()
      : "";
  }

  const value = { [curr["value"]]: prop ?? "" };

  return { ...acc, ...value };
}, {});

const formData = ref(elements);

const showUpdateFormButton = ref(false);

const buttonSaveClass = computed(() => {
  return { hidden: !showUpdateFormButton.value };
});

const isLoading = ref(false);

onMounted(async () => {
  if (!signaturesStore.hasBeenLoaded()) {
    try {
      isLoading.value = true;
      const signatures = await getSignatures({ pageSize: 20 });
      signaturesStore.setSignatures(signatures.data);
    } catch (e) {
      console.error(e);
    } finally {
      isLoading.value = false;
    }
  }
});
</script>
