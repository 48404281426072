<template>
	<Layout :class="blurContent">
		<template #content>
			<router-view></router-view>
		</template>
	</Layout>

	<ModalItem>
		<template #roleSection="{ assemblyData, role }">
			<RoleSection :assemblyData="assemblyData" :roleUser="role"></RoleSection>
		</template>
	</ModalItem>
	<GlobalLoader />
</template>

<!-- Fetch initial data  -->

<script setup>
import { computed, onMounted } from "vue";

import GlobalLoader from "./components/GlobalLoader.vue";
import Layout from "./layouts/default.vue";

import ModalItem from "./components/modalItem/ModalItem.vue";

import { useGlobalStore } from "./stores/global";
import { useCotsStore } from "./stores/cots";
import { useDialogStore } from "./stores/dialog";
import { getAssemblies } from "./services/assembly";
import { getDropdowns } from "./services/dropdowns";
import { craftAssemblies } from "./workshop/assembly";

import { useYearData } from "./composables/useYearData";
import { useDropdownsStore } from "./stores/dropdowns";
import RoleSection from "./components/roleSection.vue";
import { getSignatures } from "./services/signatures";
import { useSignaturesStore } from "./stores/signatures";
import getAssemblyData from "./alchemy/assembly";

const globalStore = useGlobalStore();
const dropdownsStore = useDropdownsStore();
const signaturesStore = useSignaturesStore();
const cotsStore = useCotsStore();
const dialogStore = useDialogStore();

// Customized blur for modal open
const blurContent = computed(() => {
	return { "blur-sm": dialogStore.isVisible };
});

onMounted(async () => {
	globalStore.toggleLoading();
	try {
		// Set global data such as: assemblies, dropdowns and signatures

		const assembliesResource = await getAssemblies({
			pageSize: 100,
			year: globalStore.selectedYear,
		});
		// Extra step because of lack of backend
		const assembliesCrafted = craftAssemblies(assembliesResource.data);

		const dropdowns = await getDropdowns();
		dropdownsStore.setDropdowns(dropdowns.data);

		const signatures = await getSignatures({ pageSize: 20 });
		signaturesStore.setSignatures(signatures.data);

		const assembliesToStore = getAssemblyData(assembliesCrafted, {
			signatures: signatures.data,
			dropdowns: dropdowns.data,
		});

		cotsStore.setAssemblies(assembliesToStore);
	} finally {
		globalStore.toggleLoading();
	}
});
</script>
