const getStartOfWeek = (date) => {
  var day = date.getDay();
  var diff = date.getDate() - day + (day == 0 ? -6 : 1); // ajusta al lunes
  return new Date(date.setDate(diff));
};

export const getWeek = () => {
  var startOfWeek = getStartOfWeek(new Date());

  var daysOfWeek = Array(7)
    .fill()
    .map((_, i) => {
      var newDate = new Date(startOfWeek.valueOf());
      newDate.setDate(newDate.getDate() + i);
      return `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`;
    });

  return daysOfWeek;
};

export const getMonth = () => {
  var startOfMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1,
  );

  startOfMonth.setDate(-startOfMonth.getDay() + 2);

  var daysOfMonth = Array(35)
    .fill()
    .map((_, i) => {
      var newDate = new Date(startOfMonth.valueOf());
      newDate.setDate(newDate.getDate() + i);
      return newDate.toISOString().substring(0, 10);
    });

  return daysOfMonth;
};
