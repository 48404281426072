import fields from "../enums/field";
import modalCategories from "../enums/modalCategory";
import datatableTypes from "../enums/datatableTypes";
import modalTypes from "../enums/modalTypes";
import gridItemsTypes from "../enums/gridItemTypes";

const dataTableColumns = [
  {
    field: fields.assemblyCotNumber,
    header: "COT",
    dataTableType: datatableTypes.TEXT,
    link: "https://joget.clasealpha.com:8443/jw/web/userview/acomercial2022/graficos/_/cot_final_crud?_mode=edit&id=",
    frozen: true,
  },
  {
    field: fields.assemblyClientOrganizationName,
    header: "Cliente",
    dataTableType: datatableTypes.TEXT,
    frozen: true,
  },
  {
    field: fields.assemblyServiceFirstAnnoucementDate,
    header: "Fecha 1ra convocatoria",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
  {
    field: fields.assemblyServiceSecondAnnoucementDate,
    header: "Fecha 2da convocatoria",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
  {
    field: fields.isAssemblyClientInWallet,
    header: "Cartera",
    dataTableType: datatableTypes.ICON,
    frozen: false,
  },
  {
    field: fields.assemblyServiceTotalPrice,
    header: "Valor cotizado",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
  {
    field: fields.assemblyClientAdminTel,
    header: "Celular de contacto",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
  {
    field: fields.assemblyServiceType,
    header: "Modalidad",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
  {
    field: fields.assemblyServiceClientMail,
    header: "Correo envia cotización",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
  {
    field: fields.assemblyClientAdvanceStatus,
    header: "Anticipo",
    dataTableType: datatableTypes.ICON,
    frozen: false,
  },
  {
    field: fields.reportsSendWoPayment,
    header: "Enviar informes sin pago?",
    color: "text-red-600",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
  {
    field: fields.invoicesMailShouldSend,
    header: "Correo al que se debe enviar factura",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
  {
    field: fields.comercialSendReportsMail,
    header: "Correo al que se debe enviar informes",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
  {
    field: fields.assemblyValuedParticipants,
    header: "Usuarios cotizados",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
  {
    field: fields.assemblyRealParticipants,
    header: "Usuarios reales",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
];

const generalSection = [
  {
    field: fields.assemblyCotNumber,
    header: "COT",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.LINK,
    link: "https://joget.clasealpha.com:8443/jw/web/userview/acomercial2022/graficos/_/cot_final_crud?_mode=edit&id=",
  },
  {
    field: fields.assemblyClientOrganizationName,
    header: "Cliente",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyServiceFirstAnnoucementDate,
    header: "Fecha 1ra convocatoria",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyServiceSecondAnnoucementDate,
    header: "Fecha 2da convocatoria",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.isAssemblyClientInWallet,
    header: "Cartera",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyServiceTotalPrice,
    header: "Valor cotizado",
    category: modalCategories.GENERAL_SECTION__WIDGETLIST,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyClientAdminTel,
    header: "Celular de contacto",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyServiceType,
    header: "Modalidad",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyServiceClientMail,
    header: "Correo envia cotización",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyClientAdvanceStatus,
    header: "Anticipo",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.reportsSendWoPayment,
    header: "Enviar informes sin pago?",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
    color: "text-red-600",
  },
  {
    field: fields.invoicesMailShouldSend,
    header: "Correo al que se debe enviar factura",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.comercialSendReportsMail,
    header: "Correo al que se debe enviar informes",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyValuedParticipants,
    header: "Usuarios cotizados",
    category: modalCategories.GENERAL_SECTION__WIDGETLIST,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyRealParticipants,
    header: "Usuarios reales",
    category: modalCategories.GENERAL_SECTION__WIDGETLIST,
    modalType: gridItemsTypes.TEXT,
  },
];

const reportsSection = [
  {
    value: fields.reportsDateSend,
    label: "Fecha de envio",
    readonly: false,
    inputType: modalTypes.DATE,
  },
  {
    value: fields.assemblyServiceReportsMail,
    label: "Correo informes",
    readonly: false,
    inputType: modalTypes.TEXT,
  },
  {
    value: fields.reportsSentBy,
    label: "Enviado por",
    readonly: false,
    inputType: modalTypes.SELECT,
  },
  {
    value: fields.reportsReviewedBy,
    label: "Revisado por",
    readonly: false,
    inputType: modalTypes.SELECT,
  },
  {
    value: fields.reportsRemarks,
    label: "Anotaciones",
    readonly: false,
    inputType: modalTypes.TEXT_AREA,
  },
];

const roleSection = [
  {
    field: "advance_received",
    header: "",
    category: 5,
    readonly: false,
    displayable: false,
    type: "button",
  },
  {
    field: "c_advance_value",
    header: "Valor de anticipo",
    category: 5,
    readonly: false,
    displayable: false,
    dataTableType: "plainText",
    modalType: "text",
  },
];

export default {
  dataTableColumns,
  generalSection,
  reportsSection,
  roleSection,
};
